import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { Modal } from "antd";
import ReactLoading from "react-loading";
import useUser from "../../hooks/useUser";
import useAddUser from "../../hooks/useAddUser";
import { updateToken } from "../../web3/app";
import { getMana } from "../../helpers";
import toast from "react-hot-toast";
import { useInitData, retrieveLaunchParams } from "@telegram-apps/sdk-react";

import logo from "../../assets/img/logo.png";

const { Sider, Content } = Layout;

const TemplateLayout = ({ children }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const [activeMenuKey, setActiveMenuKey] = useState(location?.pathname);
  const [slots, setSlots] = useState([{}, {}, {}, {}]);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const tokenUpdated = useRef(false);

  const [userData, setUserData] = useState();

  const { initDataRaw } = retrieveLaunchParams();
  const initData = useInitData();
  const teleUser = initData.user;
  const ref_from = initData.startParam;

  // let initDataRaw;
  // let ref_from = ``;
  // const teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  const { data: user } = useUser(teleUser?.id);

  const addUserMutation = useAddUser();

  useEffect(() => {
    setActiveMenuKey(location?.pathname);
  }, [location]);

  useEffect(() => {
    if (user) {
      setUserData(user);
      setSlots(extractSlots(user));
    }
  }, [user]);

  const extractSlots = (userData) => {
    const filterActiveSprite = userData.items.filter(
      (item) => item.type === "epic"
    );
    const slotArray = [];
    for (let i = 0; i < 4; i++) {
      if (filterActiveSprite[i]) {
        slotArray.push(filterActiveSprite[i]);
      } else {
        slotArray.push({});
      }
    }
    return slotArray;
  };

  const handleLogin = () => {
    setChecking();
    addUserMutation.mutate(
      {
        teleUser,
        refFrom: ref_from,
        initDataRaw,
      },
      {
        onSuccess: (data) => {
          setUserData(data);
          console.log(data);
        },
      }
    );
  };

  const handleLoggedIn = () => {
    setLoginModalOpen(false);
    navigate("/wallet", { replace: true });
  };

  const handleCancelLoginModal = () => {
    setLoginModalOpen(false);
  };

  const setChecking = () => {
    setLoginModalOpen(true);
    setIsChecked(false);
    setTimeout(() => {
      setIsChecked(true);
    }, 5000);
  };

  const handleClickREsprite = () => {
    toast.error("Only for Epic Sprites and above");
  };

  useEffect(() => {
    const updateUserToken = async () => {
      if (user && !tokenUpdated.current) {
        try {
          await updateToken(user.id, initDataRaw);
          tokenUpdated.current = true;
          console.log("User token updated successfully.");
        } catch (error) {
          console.error("Error updating user token:", error);
        }
      }
    };

    updateUserToken();
  }, [user]);

  useEffect(() => {
    if (isChecked && user) {
      setLoginModalOpen(true);
    }
  }, [isChecked, user]);

  return (
    <Layout className="relative min-h-screen overflow-hidden game-layout">
      <Sider width={56} trigger={null} collapsible>
        <Menu
          className="menu-main"
          theme=""
          mode="inline"
          defaultSelectedKeys={["/"]}
          selectedKeys={[activeMenuKey]}
        >
          <Menu.Item key="/" className="menu-home">
            <NavLink to="/" className="flex flex-col items-center gap-2">
              <span className="max-w-40 ml-0"></span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/sprite" className="menu-badge">
            <NavLink to="/sprite" className="flex flex-col items-center gap-2">
              <span className="max-w-40 ml-0"></span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/maps" className="menu-maps">
            <NavLink to="/maps" className="flex flex-col items-center gap-2">
              <span className="max-w-40 ml-0"></span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/market" className="menu-market">
            <NavLink to="/market" className="flex flex-col items-center gap-2">
              <span className="max-w-40 ml-0"></span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/news" className="menu-news">
            <NavLink to="/news" className="flex flex-col items-center gap-2">
              <span className="max-w-40 ml-0"></span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/chart" className="menu-chart">
            <NavLink to="/chart" className="flex flex-col items-center gap-2">
              <span className="max-w-40 ml-0"></span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/wallet" className="menu-wallet">
            <NavLink to="/wallet" className="flex flex-col items-center gap-2">
              <span className="max-w-40 ml-0"></span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <div className="subhead flex items-center justify-between">
          <div className="ton-connect-button">
            {userData ? (
              <NavLink to="/wallet" className="button">
                {userData.tele_username || "Join now"}
              </NavLink>
            ) : (
              <button className="button " onClick={(e) => handleLogin()}>
                <span>Join now</span>
              </button>
            )}
          </div>
          <div className="ton-amount-button">
            <NavLink to="/wallet" className="button">
              {userData
                ? Math.floor(userData.point).toLocaleString("en-US")
                : 0}
            </NavLink>
          </div>
        </div>
        <Content>
          <div className="container mx-auto max-w-[400px]">{children}</div>
          <div className="game-slots">
            {slots &&
              slots.map((item, index) => (
                <div className="item active">
                  <div className="inner">
                    <div className="number">{index + 1}</div>
                    {item.id ? (
                      <>
                        <NavLink to={`/sprite/${item.id}`} className="link">
                          <img
                            src={require(`../../assets/img/sprite/${item.image}`)}
                          />
                        </NavLink>
                        <div className="energy">
                          {Math.floor(
                            getMana(item.mana, item.mana_update_time)?.mana / 20
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="link" onClick={handleClickREsprite}></div>
                    )}
                  </div>
                </div>
              ))}
          </div>

          <Modal
            forceRender
            open={isLoginModalOpen}
            onCancel={handleCancelLoginModal}
            footer={false}
            className="modal-create-token"
          >
            <div className="text-center mt-6">
              <div className="checking mb-5">
                {!isChecked ? (
                  <>
                    <div className="flex justify-center mb-5">
                      <img className="w-[30px]" src={logo} />
                    </div>
                    <h3 className="font-medium text-[20px]  mb-5">
                      Checking your account
                    </h3>
                    <div className="flex justify-center">
                      <ReactLoading
                        type="bars"
                        height={30}
                        width={30}
                        color="#ffffff"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-center mb-5">
                      <img className="w-[90px] rounded-lg" src={logo} />
                    </div>
                    <h3 className="font-medium text-[20px]  mb-5">
                      Hi, @{userData.tele_username}
                    </h3>
                    <button
                      onClick={() => handleLoggedIn()}
                      className="px-3 text-sm py-2 rounded-lg btn"
                    >
                      <span>Continue</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </Modal>
        </Content>
      </Layout>
    </Layout>
  );
};
export default TemplateLayout;
