import React from "react";

import { TonConnectUIProvider } from "@tonconnect/ui-react";

import { SDKProvider } from "@telegram-apps/sdk-react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import TemplateLayout from "./components/Layout";
import Home from "./page/Home";
import Badge from "./page/Badge";
import Items from "./page/Items";
import Market from "./page/Market";
import MarketItems from "./page/MarketItems";
import Maps from "./page/Maps";
import Leaderboard from "./page/Leaderboard";
import Wallet from "./page/Wallet";
import WalletHistory from "./page/WalletHistory";
import WalletDeposit from "./page/WalletDeposit";
import MarketSprite from "./page/MarketSprite";
import News from "./page/News";
import SpriteDetail from "./page/SpriteDetail";
import Chart from "./page/Chart";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <TonConnectUIProvider manifestUrl={process.env.REACT_APP_MANIFESTURL}>
        {/*Root component*/}
        <SDKProvider acceptCustomStyles>
          <Router>
            <TemplateLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sprite" element={<Badge />} />
                <Route path="/sprite/items" element={<Items />} />
                <Route path="/market" element={<Market />} />
                <Route path="/market/items" element={<MarketItems />} />
                <Route path="/market/sprite" element={<MarketSprite />} />
                <Route path="/sprite/:id" element={<SpriteDetail />} />
                <Route path="/maps" element={<Maps />} />
                <Route path="/chart" element={<Chart />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/wallet/history" element={<WalletHistory />} />
                <Route path="/wallet/deposit" element={<WalletDeposit />} />
                <Route path="/news" element={<News />} />
              </Routes>
            </TemplateLayout>
          </Router>
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 2500,
            }}
          />
        </SDKProvider>
      </TonConnectUIProvider>
    </QueryClientProvider>
  );
}

export default App;
