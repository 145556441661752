import { useEffect, useState } from "react";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import { NavLink } from "react-router-dom";
import {
  useInitData,
  retrieveLaunchParams,
  initUtils,
} from "@telegram-apps/sdk-react";
import coin from "../../assets/img/coin.png";
import history from "../../assets/img/history-icon.png";

import {
  buyItem,
  getMarketItem,
  getUser,
  getUserHistory,
} from "../../web3/app";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { Modal } from "antd";

export default function Home() {
  const [userData, setUserData] = useState<any>();
  const [spriteList, setSpriteList] = useState<any>();
  const queryClient = useQueryClient();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [userHistory, setUserHistory] = useState<any>([]);

  const { initDataRaw } = retrieveLaunchParams();
  const utils = initUtils();
  const initData = useInitData();
  const teleUser = initData.user;

  // let initDataRaw;
  // let utils;
  // let ref_from = ``;
  // const teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (userData) {
      handleMarketList();
    }
  }, [userData]);

  const fetchUser = async () => {
    const user = await getUser(teleUser.id);
    if (user) {
      setUserData(user);
    }
  };

  const openHistory = async () => {
    if (userData) {
      const getHistory = await getUserHistory(userData.id, initDataRaw);
      setUserHistory(getHistory.data);
      setIsHistoryOpen(true);
    }
  };

  const closeHistory = () => {
    setIsHistoryOpen(false);
  };

  const handleMarketList = async () => {
    if (userData) {
      const getMarketList = await getMarketItem(userData.id);
      setSpriteList(getMarketList);
    }
  };

  const handleBuy = async (item: any) => {
    if (item.price > userData.point) {
      toast.error("You don't have enough token.");
      return;
    }
    try {
      await buyItem(item.item_id, userData.id, initDataRaw);
      toast.success("Item purchased successfully!");
      fetchUser();
      queryClient.invalidateQueries({ queryKey: ["getUser"] });
    } catch (error) {
      console.error("Error purchasing item:", error);
      toast.error("Unable to purchase item.");
    }
  };

  return (
    <>
      <div className="relative market">
        <div className="frame-2">
          <div className="frame-2-inner">
            <div className="head">MARKETPLACE</div>
            <div className="badge-tab flex items-center justify-center gap-1">
              <NavLink to="/market" className="market-tab-item">
                BOX
              </NavLink>
              <NavLink to="/market/sprite" className="market-tab-item current">
                SPRITE
              </NavLink>
              <NavLink to="/market/items" className="market-tab-item">
                ITEMS
              </NavLink>
            </div>
            <div className="sprite-list grid grid-cols-3 gap-1 gap-y-3">
              <div
                onClick={openHistory}
                className="sprite-item flex flex-col items-center p-4"
              >
                <img
                  src={history}
                  className="w-8 h-8 mt-3 object-contain mb-2"
                  alt="history"
                />
              </div>

              {spriteList &&
                spriteList?.map((item) => (
                  <NavLink to="" className="sprite-item">
                    <div key={item.id} className="sprite-item-inner relative">
                      <div className="img">
                        <img
                          src={require(`../../assets/img/sprite/${item.image}`)}
                          alt={item.name}
                        />
                      </div>
                      <div className="absolute bottom-[6px] text-md font-bold flex items-center justify-between w-full">
                        <div className="flex">
                          <img
                            className="item w-5 h-5 ml-1"
                            src={require(`../../assets/img/sprite/elements/${item.element}_no_bg.png`)}
                            alt={item.name}
                          />
                        </div>
                        <div className="flex ">
                          <img
                            className="item w-5 h-5"
                            src={require(`../../assets/img/sprite/ranks/${item.type}_no_bg.png`)}
                            alt={item.name}
                          />
                        </div>
                        <div className="flex">
                          <img
                            className="item w-5 h-5 mr-1"
                            src={require(`../../assets/img/claim.png`)}
                            alt={item.name}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bottom-1 w-full flex justify-center">
                      <p className="mt-[2px] ext-md font-bold text-white ml-2">
                        {item.item_price}
                      </p>
                      <div className="flex gap-1 m-1">
                        <img className="item w-4 h-4" src={coin} alt="coin" />
                      </div>
                    </div>
                    <button
                      onClick={() => handleBuy(item)}
                      className="buy-market"
                    ></button>
                  </NavLink>
                ))}
            </div>
          </div>
        </div>
      </div>

      <Modal open={isHistoryOpen} onCancel={closeHistory} footer={null}>
        <div className="h-[290px] mt-2 overflow-y-scroll">
          {userHistory.length > 0 ? (
            userHistory.map((history: any, index: number) => (
              <div
                key={index}
                className="flex bg-[#7e4802] items-center shadow-lg m-1 rounded-lg mb-1"
              >
                <img
                  src={require(`../../assets/img/sprite/${history.sprite_image}`)}
                  alt={history.sprite_name}
                  className="w-16 h-16 object-cover m-3 rounded"
                />
                <div className="flex-1 mb-4">
                  <p className="uppercase bg-[#562f00] inline-block p-1 px-2 rounded text-xs my-2">
                    {history.sprite_name}
                  </p>
                  <div className="flex items-center space-x-2">
                    <p className="flex items-center mb-1">
                      {history.price}
                      <img className="w-5 h-5 ml-1" src={coin} alt="coin" />
                    </p>
                  </div>
                  <p className="mb-1 flex items-center">
                    Type:{" "}
                    <p className="font-bold ml-2 uppercase">
                      {history.transaction_type}
                    </p>
                  </p>
                  {history.transaction_date != "null" && (
                    <p>
                      {new Date(history.transaction_date)
                        .toLocaleString("en-GB", {
                          hour12: false,
                          hour: "2-digit",
                          minute: "2-digit",
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                        .replace(",", "")}
                    </p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No transaction history available.</p>
          )}
        </div>
      </Modal>
    </>
  );
}
