import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { useInitData, retrieveLaunchParams } from "@telegram-apps/sdk-react";
import Countdown, { zeroPad } from "react-countdown";
import toast from "react-hot-toast";

import { getSpriteDetail, getUser, fighting } from "../../web3/app";
import { getMana } from "../../helpers";

import coin from "../../assets/img/coin.png";
import map1 from "../../assets/img/map1.png";

import star from "../../assets/img/star.png";
import clock from "../../assets/img/clock.png";

import win from "../../assets/img/number1.png";
import lose from "../../assets/img/close.png";
import fight from "../../assets/img/logo.png";

export default function Home() {
  const { id } = useParams();
  const [spriteData, setSpriteData] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [manaData, setManaData] = useState<any>();
  const [fightData, setFightData] = useState<any>();

  const [isFightModalOpen, setFightModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { initDataRaw } = retrieveLaunchParams();
  const initData = useInitData();
  const teleUser = initData.user;

  // let initDataRaw;
  // let teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  const isRareSprite =
    spriteData?.type !== "common" && spriteData?.type !== "uncommon";

  useEffect(() => {
    fetchSpriteDetail();
  }, [id]);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchSpriteDetail = async () => {
    const detail = await getSpriteDetail(parseInt(id));
    if (detail) {
      setSpriteData(detail);
      setManaData(getMana(detail.mana, detail.mana_update_time));
      console.log(`mana`, getMana(detail.mana, detail.mana_update_time));
    }
  };

  const fetchUser = async () => {
    const user = await getUser(teleUser.id);
    if (user) {
      setUserData(user);
    }
  };

  const handleFighting = async () => {
    if (!userData) return;
    if (!spriteData) return;
    if (userData.id != spriteData.user_id) return;
    // check mana
    const getM = getMana(spriteData.mana, spriteData.mana_update_time);
    if (getM.mana < 20) {
      toast.error(`Not enough mana`);
      return;
    }

    const fight = await fighting(userData.id, spriteData.id, 1, initDataRaw);
    setChecking();
    if (fight.status == "success") {
      setFightData(fight.data);
      fetchSpriteDetail();
    } else {
      toast.error(fight.message);
    }
  };

  const handleCancelFightModal = () => {
    setFightModalOpen(false);
  };
  const setChecking = async () => {
    setIsChecked(false);
    setFightModalOpen(true);
    const interval = setInterval(() => {
      setIsChecked(true);
    }, 5000);
    return () => clearInterval(interval);
  };

  console.log(spriteData);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <span className="text-[#000] font-medium text-[15px]">00:00:00</span>
      );
    } else {
      return (
        <span className="text-[#000] font-medium text-[15px]">
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  return (
    <>
      {spriteData && (
        <div className="detail">
          <div className="char-frame mb-3">
            <div className="char-frame-inner">
              <div className="char-frame-content">
                <div className="char-head flex items-center justify-center">
                  <div className="name">
                    {spriteData.name} #{spriteData.id}
                  </div>
                  <div className="relative">
                    <div className="w-12 mr-1 h-12">
                      <img
                        src={require(`../../assets/img/sprite/elements/${spriteData.element}.png`)}
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="w-12 h-12">
                      <img
                        src={require(`../../assets/img/sprite/ranks/${spriteData.type}.png`)}
                        alt="Rank Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="char-img">
                  <img
                    src={require(`../../assets/img/sprite/${spriteData.image}`)}
                    alt="Rank Icon"
                  />
                </div>
                {/* Char detail */}
                <div className="flex justify-center">
                  <div className="relative flex flex-col items-start">
                    <span className="text-sm font-semibold mb-1">ATK:</span>
                    <img
                      className="pr-1"
                      src={require("../../assets/img/sprite/covers/atk_detail.png")}
                      alt="ATK Icon"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="font-medium text-lg text-white mt-6 ml-7">
                        0
                      </span>
                    </div>
                  </div>
                  <div className="relative flex flex-col items-start">
                    <span className="text-sm font-semibold mb-1">DEF:</span>
                    <img
                      className="pr-1"
                      src={require("../../assets/img/sprite/covers/def_detail.png")}
                      alt="DEF Icon"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="font-medium text-lg text-white mt-6 ml-7">
                        0
                      </span>
                    </div>
                  </div>

                  <div className="relative flex flex-col items-start">
                    <span className="text-sm font-semibold mb-1">Lucky:</span>
                    <img
                      className="pr-1"
                      src={require("../../assets/img/sprite/covers/lucky_p_detail.png")}
                      alt="Lucky Point Icon"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="font-medium text-lg text-white mt-6 ml-7">
                        0
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isRareSprite && (
            <div className="maps">
              <div className="maps-list-item">
                <div className="name">Mystic Forest</div>
                <div className="inner">
                  <div className="flex justify-center items-center gap-2">
                    <img className="w-[120px] " src={map1} />
                    <div className="content flex-1">
                      <p>
                        Win rate: <span className="font-medium">90%</span>
                      </p>
                      <div className="flex items-center gap-1 mb-1 text-[#000] uppercase font-medium mt-1">
                        Reward: 5<img className="w-[18px]" src={coin} />
                      </div>
                      <button
                        // onClick={handleFighting}
                        className="btn opacity-50 is-small uppercase mt-1"
                      >
                        Go Hunt
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Modal
        forceRender
        open={isFightModalOpen}
        footer={false}
        className="modal-fight modal-char"
      >
        <div>
          {!isChecked ? (
            <div className="char">
              <div className="char-content text-center">
                <img className="m-auto w-[100px] mt-7" src={fight} />
                <p className="text-[#000]">Fighting...</p>
              </div>
            </div>
          ) : (
            <>
              <div className="char">
                {fightData && (
                  <div className="char-content">
                    {fightData.win ? (
                      <>
                        <div className="fight-result win text-center">
                          <img className="m-auto w-[100px] mt-7" src={win} />
                          <div className="title text-[34px] text-[#000]">
                            WIN
                          </div>
                          <div className="text-[#000]">
                            Reward: {fightData.reward}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="fight-result lose text-center">
                          <img className="m-auto w-[100px] mt-5" src={lose} />
                          <div className="title text-[34px] text-[#000]">
                            LOSE
                          </div>
                        </div>
                      </>
                    )}

                    <div className="text-center mt-7">
                      <button
                        onClick={handleCancelFightModal}
                        className="btn is-small"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
