import {
  TonClient4,
  fromNano,
  Address,
  Cell,
  beginCell,
  storeMessage,
} from "ton";

import { generateFromString } from "generate-avatar";

export const endpoint = process.env.REACT_APP_NETWORK_RPC;
export const client4 = new TonClient4({ endpoint });

export const mindept = parseFloat(process.env.REACT_APP_MINDEPT);
export const maxdept = parseFloat(process.env.REACT_APP_MAXDEPT);

export async function getTonBalance(friendlyAddress) {
  if (!friendlyAddress) return 0;
  try {
    const latestBlock = await client4.getLastBlock();
    const accountData = await client4.getAccount(
      latestBlock.last.seqno,
      friendlyAddress
    );
    return parseFloat(fromNano(accountData.account.balance.coins));
  } catch (error) {
    return 0;
  }
}

export async function getTx(walletAddress) {
  const latestBlock = await client4.getLastBlock();
  const seqno = latestBlock.last.seqno;
  const accountData = await client4.getAccount(
    seqno,
    Address.parse(walletAddress)
  );
  const transactions = await client4.getAccountTransactions(
    Address.parse(walletAddress),
    accountData.account.last.lt,
    accountData.account.last.hash
  );
  // console.log(`transactions`, transactions)
  console.log(`Txn Checking...`);
  for (const item of transactions) {
    try {
      const tx = item.tx;
      const inMsg = tx.inMessage;
      const inHash = beginCell()
        .store(storeMessage(inMsg))
        .endCell()
        .hash()
        .toString("hex");

      const body = inMsg.body.beginParse();
      const op = body.loadUint(32);
      if (op == 0) {
        const comment = body.loadStringTail();
        if (comment == "Payment for 91b21ad570") {
          // console.log("src", inMsg.info.src.toString());
          const amount = fromNano(inMsg.info.value.coins);
          // console.log(`amount`, amount);
          // console.log(`inHash`, inHash);
        }
      }
    } catch (error) {}
  }
}
export async function getTxByBoC(exBoc, walletAddress) {
  return retry(
    async () => {
      const latestBlock = await client4.getLastBlock();
      const seqno = latestBlock.last.seqno;
      const accountData = await client4.getAccount(
        seqno,
        Address.parse(walletAddress)
      );
      const transactions = await client4.getAccountTransactions(
        Address.parse(walletAddress),
        accountData.account.last.lt,
        accountData.account.last.hash
      );
      // console.log(`transactions`, transactions)
      console.log(`Txn Checking...`);
      for (const item of transactions) {
        const tx = item.tx;
        const inMsg = tx.inMessage;
        const extHash = Cell.fromBase64(exBoc).hash().toString("hex");
        const inHash = beginCell()
          .store(storeMessage(inMsg))
          .endCell()
          .hash()
          .toString("hex");
        // console.log(`extHash`, extHash)
        // console.log(`inHash`, inHash)
        // console.log('checking the tx', tx.hash().toString('hex'))
        if (extHash === inHash) {
          //console.log("Txn Confirmed");
          const txHash = tx.hash().toString("hex");
          // console.log(`Transaction Hash: ${txHash}`)
          // console.log(`Transaction LT: ${tx.lt}`)
          return txHash;
        }
      }
      throw new Error("Transaction not found");
    },
    { retries: 60, delay: 5000 }
  );
}

export async function retry(fn, options) {
  let lastError;
  for (let i = 0; i < options.retries; i++) {
    try {
      return await fn();
    } catch (e) {
      if (e instanceof Error) {
        lastError = e;
      }
      await new Promise((resolve) => setTimeout(resolve, options.delay));
    }
  }
  throw lastError;
}

export async function getTxByAddress(walletAddress) {
  const latestBlock = await client4.getLastBlock();
  const seqno = latestBlock.last.seqno;
  const accountData = await client4.getAccount(
    seqno,
    Address.parse(walletAddress)
  );
  const transactions = await client4.getAccountTransactions(
    Address.parse(walletAddress),
    accountData.account.last.lt,
    accountData.account.last.hash
  );
  return transactions;
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export function truncateString(str, num, amount = 16) {
  if (str.length > num) {
    return str.slice(0, amount) + "..." + str.slice(-amount);
  } else {
    return str;
  }
}
export function abbreviateNumber(n) {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export function getColor(index) {
  const colors = [
    "f97066",
    "5623c4",
    "e5e7eb",
    "ccfd07",
    "c11574",
    "2ed3b7",
    "854CFF",
    "968A64",
    "B56C76",
    "0EB75F",
    "7FCFDF",
    "183D09",
    "733C40",
    "e5e7eb",
  ];
  return colors[index];
}

export function getAvatar(string) {
  const avatar = `data:image/svg+xml;utf8,${generateFromString(string)}`;
  return avatar;
}

export function getTxnType(type) {
  let typeHtml;
  switch (type) {
    case `deposit`:
      typeHtml = {
        text: `Deposit`,
        type: `plus`,
      };
      break;
    case `withdraw`:
      typeHtml = {
        text: `Withdraw`,
        type: `minus`,
      };
      break;
    case `round_deposit`:
      typeHtml = {
        text: `Add fund`,
        type: `minus`,
      };
      break;
    case `round_refund`:
      typeHtml = {
        text: `Refund`,
        type: `plus`,
      };
      break;
    case `round_reward`:
      typeHtml = {
        text: `Reward`,
        type: `plus`,
      };
      break;
    case `ref_reward`:
      typeHtml = {
        text: `Ref Reward`,
        type: `plus`,
      };
      break;
    default:
      typeHtml = {
        text: `Deposit`,
        type: `plus`,
      };
      break;
  }

  return typeHtml;
}
