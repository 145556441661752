import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useInitData, retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { Table } from "antd";
import { getTxnType } from "../../web3/ultils";
import dayjs from "dayjs";
import tonCoin from "../../assets/img/ton.png";
import { getUser, getTonTxnByUser } from "../../web3/app";

export default function Wallet() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>();
  const [txnsData, setTxnsData] = useState<any>();

  const initData = useInitData();
  const teleUser = initData.user;

  // const teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  useEffect(() => {
    fetchUser();
  }, []);

  const txnsColumns = [
    {
      title: "TXID",
      dataIndex: "code",
      key: "code",
      render: (_, record) => {
        return (
          <>
            <p className="uppercase bg-[#562f00] inline-block p-1 px-2 rounded text-[10px]">
              {getTxnType(record.txn_type).text}
            </p>
            <p>{record.code}</p>
            <p className="text-[#FFF] text-[10px]">
              {dayjs(record.created_at.toString()).format("DD MMM YYYY, HH:mm")}{" "}
            </p>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right" as const,
      render: (_, record) => {
        return (
          <div className="amount">
            <div
              className={`text-[16px] flex gap-1 items-center justify-end ${
                getTxnType(record.txn_type).type
              }`}
            >
              {getTxnType(record.txn_type).type == "plus" ? "+" : "-"}
              {record.amount.toLocaleString("en-US")}
              <img className="w-[20px]" src={tonCoin} />
            </div>
            <div className={`uppercase text-xs text-right ${record.status}`}>
              {record.status}
            </div>
          </div>
        );
      },
    },
  ];

  const fetchUser = async () => {
    const user = await getUser(teleUser.id);
    if (user) {
      const txns = await getTonTxnByUser(user.id);
      setTxnsData(txns);
      setUserData({ ...user, txns });
    } else {
      navigate("/", { replace: true });
    }
  };

  return (
    <>
      <div className="relative wallet">
        <div className="frame-2">
          <div className="frame-2-inner">
            <div className="head">Transactions</div>
            <div className="badge-tab flex items-center justify-center gap-1">
              <NavLink to="/wallet" className="badge-tab-item ">
                WALLET
              </NavLink>
              <NavLink to="/wallet/history" className="badge-tab-item current">
                TXNS
              </NavLink>
            </div>
            <div className="txns-list">
              {txnsData && (
                <>
                  <Table
                    className="w-full table-rewards"
                    dataSource={txnsData}
                    columns={txnsColumns}
                    scroll={{ x: "max-content" }}
                    pagination={{
                      defaultPageSize: 7,
                      total: txnsData.length,
                      position: ["bottomCenter"],
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => {},
                      };
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
