import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import toncoin from "../../assets/img/ton-coin.png";

export default function Home() {
  const [userData, setUserData] = useState<any>();
  const [isCheckingModalOpen, setCheckingModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {}, []);

  // const newquest = [
  //     {
  //         id: 1,
  //         name: "Share your OG status",
  //         introl: "In telegram stories",
  //         price: 120
  //     },
  //     {
  //         id: 2,
  //         name: "Share your OG status",
  //         introl: "In telegram stories",
  //         price: 120
  //     },
  //     {
  //         id: 3,
  //         name: "Share your OG status",
  //         introl: "In telegram stories",
  //         price: 120
  //     },
  //     {
  //         id: 4,
  //         name: "Share your OG status",
  //         introl: "In telegram stories",
  //         price: 120
  //     },
  //     {
  //         id: 5,
  //         name: "Share your OG status",
  //         introl: "In telegram stories",
  //         price: 120
  //     },
  //     {
  //         id: 6,
  //         name: "Share your OG status",
  //         introl: "In telegram stories",
  //         price: 120
  //     },
  // ]

  const news = [
    {
      id: 1,
      name: "Don't have any news.",
    },
  ];

  return (
    <>
      <div className="relative leaderboard">
        <div className="frame-3">
          <div className="frame-2-inner">
            <div className="head">NEWS</div>
            <div className="leaderboard-wrp">
              <div>
                {news &&
                  news.map((item) => (
                    <div className="item">
                      <div className="flex align-items text-center">
                        <div>
                          <p className="mt-28 ml-4"></p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
