import { useEffect, useState } from "react";
import { cancelItem, getUser, newPriceItem, saleItem } from "../../web3/app";

import {
  initUtils,
  retrieveLaunchParams,
  useInitData,
} from "@telegram-apps/sdk-react";
import { Modal } from "antd";
import toast from "react-hot-toast";

export default function Home() {
  const [userData, setUserData] = useState<any>();
  const [isUnlockModalOpen, setUnlockModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [tokenValue, setTokenValue] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [loading, setLoading] = useState(false);

  const { initDataRaw } = retrieveLaunchParams();
  const utils = initUtils();
  const initData = useInitData();
  const teleUser = initData.user;

  // let initDataRaw;
  // let utils;
  // let ref_from = ``;
  // const teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      setLoading(true);
      const user = await getUser(teleUser.id);
      if (user) {
        setUserData(user);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to load user data.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDetail = () => {
    setUnlockModalOpen(false);
    setSelectedItem(null);
    setTokenValue("");
    setNewPrice("");
  };

  const handleOpenDetail = (item: any) => {
    setSelectedItem(item);
    setUnlockModalOpen(true);
  };

  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTokenValue(e.target.value);
  };

  const handleNewPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPrice(e.target.value);
  };

  const handleSale = async () => {
    if (!tokenValue) {
      toast.error("Please enter a token value");
      return;
    }

    try {
      setLoading(true);
      await saleItem(selectedItem.id, tokenValue, initDataRaw);
      toast.success("Item listed for sale successfully!");
      handleCloseDetail();
      fetchUser();
    } catch (error) {
      console.error("Error listing item for sale:", error);
      toast.error("Failed to list item for sale.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePrice = async () => {
    if (!newPrice) {
      toast.error("Please enter a new price!");
      return;
    }

    try {
      setLoading(true);
      await newPriceItem(selectedItem.id, newPrice, initDataRaw);
      toast.success("Price updated successfully!");
      handleCloseDetail();
      fetchUser();
    } catch (error) {
      console.error("Error updating price:", error);
      toast.error("Failed to update price.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      setLoading(true);
      await cancelItem(selectedItem.id, initDataRaw);
      toast.success("Item delisted successfully!");
      handleCloseDetail();
      fetchUser();
    } catch (error) {
      console.error("Error delisting item:", error);
      toast.error("Failed to delist item.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative">
        <div className="frame-3">
          <div className="frame-2-inner">
            <div className="head">YOUR BAG</div>

            <div className="mt-8 max-h-[400px] overflow-y-scroll pb-[100px] grid grid-cols-3 gap-1 gap-y-3">
              {loading && <div className="text-center text-md font-bold"></div>}
              {userData && userData.items.length === 0 && (
                <div className="not-found text-center text-[#000]">
                  No items found
                </div>
              )}

              {userData?.items.map((item: any) => (
                <div
                  className={`sprite-item ${
                    item.in_market ? "opacity-50" : ""
                  }`}
                  onClick={() => handleOpenDetail(item)}
                  key={item.id}
                >
                  <div className="sprite-item-inner relative">
                    <div className="img">
                      <img
                        src={require(`../../assets/img/sprite/${item.image}`)}
                        alt={item.name}
                      />
                    </div>
                    <div className="absolute bottom-[6px] text-md font-bold flex items-center justify-between w-full">
                      <div className="flex">
                        <img
                          className="item w-5 h-5 ml-1"
                          src={require(`../../assets/img/sprite/elements/${item.element}_no_bg.png`)}
                          alt={item.name}
                        />
                      </div>
                      <div className="flex ">
                        <img
                          className="item w-5 h-5"
                          src={require(`../../assets/img/sprite/ranks/${item.type}_no_bg.png`)}
                          alt={item.name}
                        />
                      </div>
                      <div className="flex">
                        <img
                          className="item w-5 h-5 mr-1"
                          src={require(`../../assets/img/claim.png`)}
                          alt={item.name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <Modal
                forceRender
                open={isUnlockModalOpen}
                onCancel={handleCloseDetail}
                footer={false}
                className="modal-create-token"
              >
                {selectedItem && (
                  <div className="char-frame mb-3">
                    <div className="char-frame-inner">
                      <div className="char-frame-content">
                        <div className="char-head flex items-center justify-center">
                          <div className="name">
                            {selectedItem.name} #{selectedItem.id}
                          </div>
                          <div className="relative">
                            <div className="w-12 mr-1 h-12">
                              <img
                                src={require(`../../assets/img/sprite/elements/${selectedItem.element}.png`)}
                                alt="Element Icon"
                              />
                            </div>
                          </div>
                          <div className="relative">
                            <div className="w-12 h-12">
                              <img
                                src={require(`../../assets/img/sprite/ranks/${selectedItem.type}.png`)}
                                alt="Rank Icon"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="char-img">
                          <img
                            src={require(`../../assets/img/sprite/${selectedItem.image}`)}
                            alt="Unlocked Sprite"
                          />
                        </div>

                        <div className="flex justify-center">
                          <div className="relative flex flex-col items-start">
                            <span className="text-sm font-semibold mb-1">
                              ATK:
                            </span>
                            <img
                              className="pr-1"
                              src={require("../../assets/img/sprite/covers/atk_detail.png")}
                              alt="ATK Icon"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                              <span className="font-medium text-lg text-white mt-6 ml-7">
                                0
                              </span>
                            </div>
                          </div>
                          <div className="relative flex flex-col items-start">
                            <span className="text-sm font-semibold mb-1">
                              DEF:
                            </span>
                            <img
                              className="pr-1"
                              src={require("../../assets/img/sprite/covers/def_detail.png")}
                              alt="DEF Icon"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                              <span className="font-medium text-lg text-white mt-6 ml-7">
                                0
                              </span>
                            </div>
                          </div>
                          <div className="relative flex flex-col items-start">
                            <span className="text-sm font-semibold mb-1">
                              Lucky:
                            </span>
                            <img
                              className="pr-1"
                              src={require("../../assets/img/sprite/covers/lucky_p_detail.png")}
                              alt="Lucky Point Icon"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                              <span className="font-medium text-lg text-white mt-6 ml-7">
                                0
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedItem.in_market == 0 ? (
                      <div>
                        <div className="text-center flex gap-2 justify-center">
                          <input
                            value={tokenValue}
                            onChange={handleTokenChange}
                            type="number"
                            step="1"
                            className="w-[55%] rounded-xl bg-center bg-[url('/src/assets/img/amout_bg.png')] bg-[#bca282] bg-cover bg-no-repeat border-none text-center"
                            placeholder="Token"
                          />
                          <button
                            className="btn is-small secondary mb-2"
                            onClick={handleSale}
                          >
                            {loading ? "Processing..." : "SALE"}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="text-center mt-4 flex justify-end gap-2">
                          <input
                            value={newPrice}
                            onChange={handleNewPriceChange}
                            type="number"
                            step="1"
                            className="w-[55%] rounded-xl bg-center bg-[url('/src/assets/img/amout_bg.png')] bg-[#bca282] bg-cover bg-no-repeat border-none text-center"
                            placeholder="New Price"
                          />
                          <button
                            className="btn is-small secondary mb-2"
                            onClick={handleUpdatePrice}
                          >
                            {loading ? "Processing..." : "NEW PRICE"}
                          </button>
                        </div>
                        <div className="text-center flex justify-end gap-2">
                          <button
                            className="btn is-small mb-2"
                            onClick={handleCancel}
                          >
                            {loading ? "Processing..." : "DELIST"}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
