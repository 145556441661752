// hooks/useAddUser.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addUser } from "../web3/app";

const useAddUser = () => {
  return useMutation({
    mutationFn: async ({ teleUser, refFrom, initDataRaw }: any) => {
      return await addUser(teleUser, refFrom, initDataRaw);
    },
  });
};

export default useAddUser;
