import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export default function Home() {

    const [userData, setUserData] = useState<any>();
    const [isCheckingModalOpen, setCheckingModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    // const items_list = [
    //     {
    //         id: 1,
    //         name: "Energy",
    //         img: items_energy,
    //         energy: 12
    //     },
    //     {
    //         id: 2,
    //         name: "Key",
    //         img: items_key,
    //         energy: 3
    //     }
    // ]

    const items_list = [];

    useEffect(() => {
        
	}, []);

    return(
        <>
        <div className="relative">
            <div className="frame-2">
                <div className="frame-2-inner">
                    <div className="head">YOUR BAG</div>
                    <div className="badge-tab flex items-center justify-center gap-1">
                        <NavLink to="/sprite" className="badge-tab-item "
                        >
                            SPRITE
                        </NavLink>
                        <NavLink to="/sprite/items" className="badge-tab-item current"
                        >
                            ITEMS
                        </NavLink>
                    </div>
                    {
                        items_list.length == 0 &&
                            <div className="not-found text-center text-[#000] mt-10">
                                No item found
                            </div>
                    }
                    <div className="sprite-list grid grid-cols-3 gap-1 gap-y-3">
                        {
                            items_list &&
                            items_list.map((item, index) => (
                                // <NavLink to={`/items/${item.id}`} className="items-item">
                                <div className="items-item">
                                    <div className="items-item-inner">
                                        <div className="img">
                                            <img src={item.img} />
                                        </div>
                                        <div className="quantity">{item.energy}</div>
                                    </div>
                                </div>
                                // </NavLink>
                            ))
                        }
                        
                    </div>
                    
                </div>
            </div>
            </div>
        </>
    );
}