import { useQuery } from "@tanstack/react-query";
import { getUser } from "../web3/app";

const useUser = (userId) => {
  return useQuery({
    queryKey: ["getUser"],
    queryFn: async () => getUser(userId),
    enabled: !!userId,
  });
};

export default useUser;
