import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import { Form, Input } from "antd";
import { TonConnectButton } from "@tonconnect/ui-react";

import { useInitData, retrieveLaunchParams } from "@telegram-apps/sdk-react";

import { deposit } from "../../web3/ton";
import { withdraw } from "../../web3/app";
import {
  getTx,
  sleep,
  getTonBalance,
  truncateString,
  getAvatar,
  getTxnType,
} from "../../web3/ultils";
import toast from "react-hot-toast";
import tonCoin from "../../assets/img/ton.png";
import { getUser, addPoint, getTonTxnByUser } from "../../web3/app";

export default function Wallet() {
  const navigate = useNavigate();
  const [tonConnect] = useTonConnectUI();
  const walletAddress = useTonAddress();
  const [userData, setUserData] = useState<any>();
  const [tonAmount, setTonAmount] = useState(0);
  const [depositForm] = Form.useForm();
  const [tonBalance, setTonBalance] = useState(0);

  const { initDataRaw } = retrieveLaunchParams();
  const initData = useInitData();
  const teleUser = initData.user;

  // let initDataRaw;
  // let teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleDisconnect = async () => {
    await tonConnect.disconnect();
  };

  const handleDeposit = async () => {
    // check balance
    const tonBalance = await getTonBalance(walletAddress);
    if (
      tonAmount == 0 ||
      tonAmount + parseFloat(process.env.REACT_APP_DEPOSIT_FEE) > tonBalance
    ) {
      toast.error(`Insufficient balance.`);
      return;
    }
    const deposited = await deposit(
      userData.tele_id,
      tonAmount,
      walletAddress,
      tonConnect
    );
    if (deposited.status == "success") {
      await sleep(5000);
    } else {
      toast.error(deposited.message);
    }
  };

  const handleWithdraw = async () => {
    await fetchUser();
    if (tonAmount == 0 || tonAmount > userData.ton) {
      toast.error(`Insufficient balance.`);
      return;
    }
    const withdrawned = await withdraw(
      userData.id,
      tonAmount,
      walletAddress,
      initDataRaw
    );
    if (withdrawned.status == "success") {
      toast.success(withdrawned.message);
    } else {
      toast.error(withdrawned.message);
    }
  };

  useEffect(() => {
    fetchTonBalance();
    const interval = setInterval(() => {
      fetchTonBalance();
      fetchUser();
    }, 15000);
    return () => clearInterval(interval);
  }, [walletAddress]);

  const fetchUser = async () => {
    const user = await getUser(teleUser.id);
    if (user) {
      const txns = await getTonTxnByUser(user.id);
      setUserData({ ...user, txns });

      // check wallet
      if (walletAddress && user.is_wallet_connected == 0) {
        const updated = await addPoint(
          {
            tg_id: teleUser.id,
            amount: 1000,
            wallet: walletAddress,
            point_type: "wallet",
          },
          initDataRaw
        ); // connect wallet
      }
    } else {
      navigate("/", { replace: true });
    }
  };

  const fetchTonBalance = async () => {
    setTonBalance(await getTonBalance(walletAddress));
  };

  return (
    <>
      <div className="relative wallet">
        <div className="frame-2">
          <div className="frame-2-inner">
            <div className="head">{userData?.tele_username}</div>
            <div className="badge-tab flex items-center justify-center gap-1">
              <NavLink to="/wallet" className="badge-tab-item current">
                WALLET
              </NavLink>
              <NavLink to="/wallet/history" className="badge-tab-item">
                TXNS
              </NavLink>
            </div>

            {walletAddress ? (
              <>
                <div className="leaderboards p-2 text-white mb-5 flex items-end justify-between gap-2">
                  <div>
                    <h2 className="text-[12px] mb-1">Connected Wallet</h2>
                    <p className="text-[16px] ">
                      {truncateString(walletAddress.toString(), 4, 6)}
                    </p>
                    <div className="flex items-center gap-2 text-[16px] mt-3">
                      <div>{tonBalance.toLocaleString("en-US")}</div>
                      <img className="w-[20px]" src={tonCoin} />
                    </div>
                  </div>

                  <button
                    className="px-2 text-xs py-1 rounded-md text-white bg-[#562F00] flex justify-center items-center gap-2"
                    onClick={() => {
                      handleDisconnect();
                    }}
                  >
                    <span>Disconnect</span>
                  </button>
                </div>
              </>
            ) : (
              <div className="leaderboards p-2 text-white mb-5 flex items-end justify-center gap-2">
                <TonConnectButton className="ton-connect-button" />
              </div>
            )}

            <div
              className={`deposit mt-3 pb-2 ${
                walletAddress ? `` : `opacity-50 pointer-events-none`
              } `}
            >
              <Form form={depositForm} layout="vertical">
                <Form.Item name="tonAmount">
                  <Input
                    type="number"
                    placeholder="TON AMOUNT"
                    value={tonAmount}
                    onChange={(e) => {
                      setTonAmount(parseFloat(e.target.value));
                    }}
                  />
                </Form.Item>
              </Form>

              <div className="flex justify-between mt-2">
                <button
                  className="btn secondary"
                  onClick={() => {
                    handleDeposit();
                  }}
                >
                  <span>DEPOSIT</span>
                </button>
                <button
                  className="btn primary"
                  onClick={() => {
                    handleWithdraw();
                  }}
                >
                  <span>WITHDRAW</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
