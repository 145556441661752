import axios from "axios";
// import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';

const api_endpoint = process.env.REACT_APP_API;

export const getUser = async (teleId) => {
  try {
    const result = await axios.get(`${api_endpoint}/users/${teleId}`);
    return result.data.data;
  } catch (error) {
    console.log(`error`, error);
    return;
  }
};

export const addUser = async (teleUser, refFrom, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/users/create`,
    {
      tele_id: teleUser.id,
      tele_username: teleUser.username ? teleUser.username : teleUser.id,
      name: `${teleUser.firstName} ${teleUser.lastName}`,
      ref_from: refFrom,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data.user;
};

export const addPoint = async (data, initDataRaw) => {
  const result = await axios.post(
    `${process.env.REACT_APP_API}/users/point`,
    {
      id: data.tg_id,
      amount: data.amount,
      wallet: data.wallet,
      point_type: data.point_type,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data.user;
};

export const createFirstSprite = async (user_id, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/app/createFirstSprite`,
    {
      user_id: user_id,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data;
};

export const updateToken = async (user_id, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/app/updateToken`,
    {
      user_id: user_id,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data;
};

export const openBox = async (user_id, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/app/openBox`,
    {
      user_id: user_id,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data;
};

export const getChart = async () => {
  const result = await axios.get(`${api_endpoint}/swap/chart_day`);
  return result.data;
};

export const tonWill = async (user_id, amount, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/swap/ton-will`,
    {
      user_id: user_id,
      amount: amount,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data;
};

export const tokenWill = async (user_id, amount, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/swap/token-will`,
    {
      user_id: user_id,
      amount: amount,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data;
};

export const swapTokenToTon = async (user_id, amount, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/swap/token-to-ton`,
    {
      user_id: user_id,
      amount: amount,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data;
};

export const swapTonToToken = async (user_id, amount, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/swap/ton-to-token`,
    {
      user_id: user_id,
      amount: amount,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data;
};

export const getSpriteDetail = async (id: number) => {
  try {
    const result = await axios.get(`${api_endpoint}/app/getSpriteDetail/${id}`);
    return result.data.data;
  } catch (error) {
    console.log(`error`, error);
    return;
  }
};

export const fighting = async (user_id, item_id, map_id, initDataRaw) => {
  const result = await axios.post(
    `${api_endpoint}/app/fighting`,
    {
      user_id: user_id,
      item_id: item_id,
      map_id: map_id,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data;
};

export const withdraw = async (
  userId: number,
  amount: number,
  toWallet: string,
  initDataRaw
) => {
  try {
    const result = await axios.post(
      `${api_endpoint}/wallet/withdraw`,
      {
        user_id: userId,
        amount: amount,
        to_wallet: toWallet,
      },
      {
        headers: {
          Authorization: `tma ${initDataRaw}`,
        },
      }
    );

    return {
      status: `success`,
      message: `Withdrawal is being processed.`,
      data: result.data.txn,
    };
  } catch (error) {
    console.log(`error`, error);
    return {
      status: `error`,
      message: `Unable to Withdrawal.`,
    };
  }
};

export const getTonTxnByUser = async (userId: number) => {
  const result = await axios.get(`${api_endpoint}/wallet/txns/${userId}`);
  return result.data.txns;
};

export const saleItem = async (item_id, price, initDataRaw) => {
  const result = await axios.post(
    `${process.env.REACT_APP_API}/market/sale`,
    {
      item_id: item_id,
      price: price,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data.user;
};

export const newPriceItem = async (item_id, newPrice, initDataRaw) => {
  const result = await axios.post(
    `${process.env.REACT_APP_API}/market/updatePrice`,
    {
      item_id: item_id,
      newPrice: newPrice,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data.user;
};

export const cancelItem = async (item_id, initDataRaw) => {
  const result = await axios.post(
    `${process.env.REACT_APP_API}/market/cancelMarket`,
    {
      item_id: item_id,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data.user;
};

export const buyItem = async (item_id, user_id, initDataRaw) => {
  const result = await axios.post(
    `${process.env.REACT_APP_API}/market/buy`,
    {
      item_id: item_id,
      buyerId: user_id,
    },
    {
      headers: {
        Authorization: `tma ${initDataRaw}`,
      },
    }
  );
  return result.data.user;
};

export const getMarketItem = async (user_id) => {
  const result = await axios.post(
    `${process.env.REACT_APP_API}/market/available`,
    {
      userId: user_id,
    }
  );
  return result.data.data;
};

export const getUserHistory = async (user_id, initDataRaw) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API}/market/getUserHistory/${user_id}`,
      {
        headers: {
          Authorization: `tma ${initDataRaw}`,
        },
      }
    );
    return result.data; // Trả về dữ liệu từ API
  } catch (error) {
    console.error("Error fetching user history.");
    throw error; // Ném lỗi để xử lý bên ngoài
  }
};
