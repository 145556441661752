import { TonConnectUI } from "@tonconnect/ui-react";
import { beginCell, toNano } from "ton";
import { getTxByBoC } from './ultils';

const systemAddress = process.env.REACT_APP_SYSTEM_ADDRESS;

export async function deposit(teleId: string, tonAmount: number, walletAddress: string, tonConnect: TonConnectUI){
    const body = beginCell()
        .storeUint(0, 32)
        .storeStringTail(teleId)
        .endCell();
    const depositFee = parseFloat(process.env.REACT_APP_DEPOSIT_FEE); // TON
    const tx = {
        validUntil: Date.now() + 5 * 60 * 1000,
        messages: [
            {
                address: systemAddress,
                amount: toNano(tonAmount + depositFee).toString(),
                payload: body.toBoc().toString("base64")
            },
        ],
    }
    try{
        const txn = await tonConnect.sendTransaction(tx)
        const txHash = await getTxByBoC(txn.boc, walletAddress)
        if(txHash){
            return {
                status: 'success',
                message: `Deposit order is being processed.`,
                txHash: txHash
            }
        }
        return {
            status: 'success',
            message: `Unable to Deposit.`
        }
    } catch (e) {
        console.log(e)
        return {
            status: 'error',
            message: `Unable to Deposit.`
        }
    }
}
