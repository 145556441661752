import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import boxExpensive from "../../assets/img/box_expensive.gif";
import boxItemSkill from "../../assets/img/box_item.gif";
import coin from "../../assets/img/coin.png";
import openBoxGift from "../../assets/img/openBoxGift.gif";
import common from "../../assets/img/sprite/ranks/common_no_bg.png";
import uncommon from "../../assets/img/sprite/ranks/uncommon_no_bg.png";
import rare from "../../assets/img/sprite/ranks/rare_no_bg.png";
import epic from "../../assets/img/sprite/ranks/epic_no_bg.png";
import rankFive from "../../assets/img/sprite/ranks/rank_five.png";
import rankSix from "../../assets/img/sprite/ranks/rank_six.png";
import toast from "react-hot-toast";
import { getUser } from "../../web3/app";
import { Modal } from "antd";
import useOpenBox from "../../hooks/useOpenBox";
import useUser from "../../hooks/useUser";
import {
  initUtils,
  retrieveLaunchParams,
  useInitData,
} from "@telegram-apps/sdk-react";

export default function Home() {
  const [unlockedData, setUnlockedData] = useState<any>();
  const [isUnlockModalOpen, setUnlockModalOpen] = useState(false);
  const [isGifVisible, setIsGifVisible] = useState(false);

  const { initDataRaw } = retrieveLaunchParams();
  const utils = initUtils();
  const initData = useInitData();
  const teleUser = initData.user;

  // let initDataRaw;
  // let utils;
  // let ref_from = ``;
  // const teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  const { data: user } = useUser(teleUser.id);

  const openBoxMutation = useOpenBox();

  const handleBuyNow = () => {
    if (!user || user.point < 2000) {
      toast.error("You don't have enough token.");
      return;
    }

    setIsGifVisible(true);

    setTimeout(() => {
      setIsGifVisible(false);
      setUnlockModalOpen(true);

      openBoxMutation.mutate(
        { userId: user.id, initDataRaw },
        {
          onSuccess: (data) => {
            setUnlockedData(data);
            console.log(data);
          },
        }
      );
    }, 3000);
  };

  const handleBuyNowClose = () => {
    setUnlockModalOpen(false);
    setUnlockedData(null);
  };

  return (
    <>
      <div className="relative market">
        <div className="frame-2">
          <div className="frame-2-inner">
            <div className="head">MARKETPLACE</div>
            <div className="badge-tab flex items-center justify-center gap-1">
              <NavLink to="/market" className="market-tab-item current">
                BOX
              </NavLink>
              <NavLink to="/market/sprite" className="market-tab-item">
                SPRITE
              </NavLink>
              <NavLink to="/market/items" className="market-tab-item">
                ITEMS
              </NavLink>
            </div>
            <div className="max-h-[400px] overflow-y-scroll pb-[100px]">
              {/* Box 1 */}
              <div className="relative box-item ">
                <div className="flex">
                  <div className="flex justify-center">
                    <img
                      className="w-24"
                      src={openBoxGift}
                      alt="Open Box Gift"
                    />
                  </div>

                  <div className="flex flex-col mt-2 items-start">
                    <p className="text-black text-base font-normal">WOOD BOX</p>
                    <p className="text-black text-[10px] mt-1 mb-1">
                      OPEN BOX TO GET SPRITES
                    </p>
                    <div className="flex items-center space-x-1">
                      <p className="text-black text-xs">TYPE:</p>
                      <img className="w-5 h-5" src={common} alt="Common Type" />
                      <img
                        className="w-5 h-5"
                        src={uncommon}
                        alt="Uncommon Type"
                      />
                      <img
                        className="w-6 mb-[3px] h-6"
                        src={rare}
                        alt="Rare Type"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between pb-2 ml-1">
                  <div className="flex items-center">
                    <p className="text-black mr-1">PRICE: 2000</p>
                    <img className="w-5 h-5" src={coin} alt="coin" />
                  </div>
                  <button
                    onClick={handleBuyNow}
                    className="btn is-small-xs text-white mr-1 font-bold text-xs px-4 rounded"
                  >
                    Buy
                  </button>
                </div>
              </div>
              {isGifVisible && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                  <img
                    src={openBoxGift}
                    alt="GIF Opening Box"
                    className="w-72 h-auto"
                  />
                </div>
              )}

              {/* Open sprite Modal */}
              <Modal
                forceRender
                open={isUnlockModalOpen}
                onCancel={handleBuyNowClose}
                footer={false}
                className="modal-create-token"
              >
                {unlockedData && (
                  <div className="char-frame mb-3">
                    <div className="char-frame-inner">
                      <div className="char-frame-content">
                        <div className="char-head flex items-center justify-center">
                          <div className="name">
                            {unlockedData.name} #{unlockedData.id}
                          </div>
                          <div className="relative">
                            <div className="w-12 mr-1 h-12">
                              <img
                                src={require(`../../assets/img/sprite/elements/${unlockedData.element}.png`)}
                                alt="Element Icon"
                              />
                            </div>
                          </div>
                          <div className="relative">
                            <div className="w-12 h-12">
                              <img
                                src={require(`../../assets/img/sprite/ranks/${unlockedData.type}.png`)}
                                alt="Rank Icon"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="char-img">
                          <img
                            src={require(`../../assets/img/sprite/${unlockedData.image}`)}
                            alt="Unlocked Sprite"
                          />
                        </div>
                        {/* Char detail */}
                        <div className="flex justify-center">
                          <div className="relative flex flex-col items-start">
                            <span className="text-sm font-semibold mb-1">
                              ATK:
                            </span>
                            <img
                              className="pr-1"
                              src={require("../../assets/img/sprite/covers/atk_detail.png")}
                              alt="ATK Icon"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                              <span className="font-medium text-lg text-white mt-6 ml-7">
                                0
                              </span>
                            </div>
                          </div>
                          <div className="relative flex flex-col items-start">
                            <span className="text-sm font-semibold mb-1">
                              DEF:
                            </span>
                            <img
                              className="pr-1"
                              src={require("../../assets/img/sprite/covers/def_detail.png")}
                              alt="DEF Icon"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                              <span className="font-medium text-lg text-white mt-6 ml-7">
                                0
                              </span>
                            </div>
                          </div>

                          <div className="relative flex flex-col items-start">
                            <span className="text-sm font-semibold mb-1">
                              Lucky:
                            </span>
                            <img
                              className="pr-1"
                              src={require("../../assets/img/sprite/covers/lucky_p_detail.png")}
                              alt="Lucky Point Icon"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                              <span className="font-medium text-lg text-white mt-6 ml-7">
                                0
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Modal>
              <div className="relative box-item">
                <div className="flex">
                  <div className="flex justify-center">
                    <img
                      className="w-24"
                      src={boxExpensive}
                      alt="Open Box Gift"
                    />
                  </div>

                  <div className="flex flex-col mt-2 items-start">
                    <p className="text-black text-base font-normal">
                      MYSTERY BOX
                    </p>
                    <p className="text-black text-[10px] mt-1 mb-2">
                      OPEN BOX TO GET SPRITES
                    </p>
                    <div className="flex items-center space-x-[2px]">
                      <p className="text-black text-xs">TYPE:</p>
                      <img className="w-6 h-6" src={epic} alt="Common Type" />
                      <img
                        className="w-6 h-6"
                        src={rankFive}
                        alt="Uncommon Type"
                      />
                      <img className="w-6 h-6" src={rankSix} alt="Rare Type" />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between pb-2 mb-2 ml-1">
                  <div className="flex items-center">
                    <p className="text-black mr-1">PRICE: ---- </p>
                  </div>
                  <button className="btn is-small-xs text-white mr-1 font-bold text-xs px-4 rounded opacity-50">
                    Buy
                  </button>
                </div>
              </div>
              <div className="relative box-item">
                <div className="flex">
                  <div className="flex justify-center">
                    <img
                      className="w-24"
                      src={boxItemSkill}
                      alt="Open Box Gift"
                    />
                  </div>

                  <div className="flex flex-col mt-2 items-start">
                    <p className="text-black text-base font-normal">
                      ITEM PACK
                    </p>
                    <p className="text-black text-[10px] mt-1 mb-2">
                      OPEN BOX TO GET ITEMS
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between pb-2 mb-2 ml-1">
                  <div className="flex items-center">
                    <p className="text-black mr-1">PRICE: ----</p>
                  </div>
                  <button className="btn is-small-xs text-white mr-1 font-bold text-xs px-4 rounded opacity-50">
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
