import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal } from "antd"; // Giữ lại vì vẫn sử dụng cho các modal khác
import { useInitData, retrieveLaunchParams } from "@telegram-apps/sdk-react";
import { initUtils } from "@telegram-apps/sdk";
import ReactLoading from "react-loading";
import toast from "react-hot-toast";

import { getUser, openBox } from "../../web3/app";

import box from "../../assets/img/box.png";
import coin from "../../assets/img/coin.png";
import openBoxGift from "../../assets/img/openBoxGift.gif";
import common from "../../assets/img/sprite/ranks/common_no_bg.png";
import uncommon from "../../assets/img/sprite/ranks/uncommon_no_bg.png";
import rare from "../../assets/img/sprite/ranks/rare_no_bg.png";
import { useQueryClient } from "@tanstack/react-query";

export default function Home() {
  const [userData, setUserData] = useState<any>();
  const [mainSprite, setMainSprite] = useState<any>(null);
  const [unlockedData, setUnlockedData] = useState<any>();
  const [isUnlockModalOpen, setUnlockModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [isGifVisible, setIsGifVisible] = useState(false);
  const [isBuyOverlayVisible, setBuyOverlayVisible] = useState(false);

  const queryClient = useQueryClient();

  const { initDataRaw } = retrieveLaunchParams();
  const utils = initUtils();
  const initData = useInitData();
  const teleUser = initData.user;

  // let initDataRaw;
  // let utils;
  // let ref_from = ``;
  // const teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  useEffect(() => {
    fetchUser();
  }, [unlockedData]);

  useEffect(() => {
    if (userData?.items?.length > 0) {
      setMainSprite(userData.items[0]);
    }
  }, [userData]);

  const handleItemClick = (item: any) => {
    setMainSprite(item);
  };

  const fetchUser = async () => {
    const user = await getUser(teleUser.id);
    if (user) {
      setUserData(user);
    }
  };

  const handleUnlockSprite = () => {
    setBuyOverlayVisible(true); // Hiển thị overlay "Buy Now"
  };

  const handleBuyNow = async () => {
    if (!userData || userData.point < 2000) {
      toast.error("You don't have enough token.");
      return;
    }

    setBuyOverlayVisible(false); // Ẩn overlay "Buy Now"
    setIsGifVisible(true); // Hiển thị GIF

    setTimeout(async () => {
      setIsGifVisible(false); // Ẩn GIF sau 3 giây
      setUnlockModalOpen(true); // Mở modal "Open Box"
      setIsUnlocking(true); // Đang mở hộp

      const unlocked = await openBox(userData.id, initDataRaw);
      if (unlocked.status === "success") {
        setUnlockedData(unlocked.data);
        setIsChecked(true);
        queryClient.invalidateQueries({ queryKey: ["getUser"] });
      } else {
        toast.error(unlocked.message);
      }
    }, 3000); // Hiển thị GIF trong 3 giây
  };

  function generateRandomId() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let id = "";
    for (let i = 0; i < 8; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }

  const handleBuyNowClose = () => {
    setUnlockModalOpen(false);
    setIsChecked(false);
    setUnlockedData(null);
  };

  return (
    <div className="home-wrp">
      {/* Main sprite */}
      {mainSprite && (
        <>
          <div className="char-frame mb-3">
            <div className="char-frame-inner">
              <div className="char-frame-content">
                <div className="char-head flex items-center justify-center">
                  <div className="name">
                    {mainSprite.name} #{mainSprite.id}
                  </div>
                  <div className="relative">
                    <div className="w-12 mr-1 h-12">
                      <img
                        src={require(`../../assets/img/sprite/elements/${mainSprite.element}.png`)}
                        alt="Element Icon"
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div className="w-12 h-12 ">
                      <img
                        src={require(`../../assets/img/sprite/ranks/${mainSprite.type}.png`)}
                        alt="Rank Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="char-img">
                  <img
                    src={require(`../../assets/img/sprite/${mainSprite.image}`)}
                    alt="Sprite"
                  />
                </div>
                {/* Char detail */}
                <div className="flex justify-center">
                  <div className="relative flex flex-col items-start">
                    <span className="text-sm font-semibold mb-1">ATK:</span>
                    <img
                      className="pr-1"
                      src={require("../../assets/img/sprite/covers/atk_detail.png")}
                      alt="ATK Icon"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="font-medium text-lg text-white mt-6 ml-7">
                        0
                      </span>
                    </div>
                  </div>
                  <div className="relative flex flex-col items-start">
                    <span className="text-sm font-semibold mb-1">DEF:</span>
                    <img
                      className="pr-1"
                      src={require("../../assets/img/sprite/covers/def_detail.png")}
                      alt="DEF Icon"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="font-medium text-lg text-white mt-6 ml-7">
                        0
                      </span>
                    </div>
                  </div>

                  <div className="relative flex flex-col items-start">
                    <span className="text-sm font-semibold mb-1">Lucky:</span>
                    <img
                      className="pr-1"
                      src={require("../../assets/img/sprite/covers/lucky_p_detail.png")}
                      alt="Lucky Point Icon"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="font-medium text-lg text-white mt-6 ml-7">
                        0
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 pb-2 grid grid-cols-3 gap-1 gap-y-3">
            {userData?.items.map((item: any) => (
              <div
                className={`sprite-item-inner sprite-item relative ${
                  item.in_market ? "opacity-60" : ""
                }`}
                onClick={() => handleItemClick(item)}
                key={generateRandomId()}
              >
                <div className="img-2">
                  <img
                    src={require(`../../assets/img/sprite/${item.image}`)}
                    alt={item.name}
                  />
                </div>
                {item.in_market ? (
                  <div className="absolute inset-0 flex items-center transform rotate-[-40deg] justify-center text-red-500 font-bold text-lg">
                    FOR SALE
                  </div>
                ) : (
                  <div className="absolute bottom-1 left-4 text-md font-bold text-white opacity-0 animate-bounceAndFadeOut flex items-center">
                    +{(item.token_per_second * 4).toFixed(4)}
                    <div className="flex gap-1 m-1">
                      <img className="item w-4 h-4" src={coin} alt="coin" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
      <>
        <div className="notify-box">
          <div className="title">Mystery Box</div>
          <div className="inner">
            <div>
              <div className="text-center text-black">
                Buy Mystery Boxes to get Sprites
              </div>
              <div className="char-img text-center">
                <img className="m-auto w-12 mt-2" src={box} alt="Box" />
              </div>
              <div className="char-detail flex justify-center">
                <button
                  onClick={handleUnlockSprite}
                  className="btn is-small mt-2"
                >
                  OPEN
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Custom Buy Now Overlay */}
        {isBuyOverlayVisible && (
          <div
            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
            onClick={() => setBuyOverlayVisible(false)}
          >
            <div
              className="notify-box-home inner p-4 sm:p-6 rounded-lg text-center shadow-md max-w-sm w-full mx-4 relative"
              role="dialog"
              aria-modal="true"
            >
              <div className="relative top-[-1px]">
                <h2 className="text-sm text-white">NOTIFICATION</h2>
                <div className="flex flex-row items-center justify-between p-4 ml-4">
                  {/* Phần bên trái: Hình ảnh của hộp */}
                  <div className="flex justify-center">
                    <img
                      className="w-24"
                      src={openBoxGift}
                      alt="Open Box Gift"
                    />
                  </div>

                  {/* Phần bên phải: Chữ và hình ảnh nhỏ */}
                  <div className="mt-4 mr-4 sm:mt-0 sm:ml-6 flex flex-col items-start">
                    <p className="text-black text-xl font-normal">WOOD BOX</p>
                    <p className="text-black text-xs mb-2">
                      OPEN BOX TO GET SPRITES
                    </p>
                    <div className="flex items-center space-x-2">
                      <p className="text-black">TYPE:</p>
                      <img className="w-5 h-5" src={common} alt="Common Type" />
                      <img
                        className="w-5 h-5"
                        src={uncommon}
                        alt="Uncommon Type"
                      />
                      <img
                        className="w-6 mb-[3px] h-6"
                        src={rare}
                        alt="Rare Type"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center ml-8">
                    <p className="text-black mr-1">PRICE: 2000</p>
                    <img className="w-5 h-5" src={coin} alt="coin" />
                  </div>
                  <button
                    onClick={handleBuyNow}
                    className="btn is-small-xs text-white font-bold text-xs px-4 rounded mr-8"
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isGifVisible && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <img
              src={openBoxGift}
              alt="GIF Opening Box"
              className="w-72 h-auto"
            />
          </div>
        )}

        {/* Open sprite Modal */}
        <Modal
          forceRender
          open={isUnlockModalOpen}
          onCancel={handleBuyNowClose}
          footer={false}
          className="modal-create-token"
        >
          {unlockedData && (
            <div className="char-frame mb-3">
              <div className="char-frame-inner">
                <div className="char-frame-content">
                  <div className="char-head flex items-center justify-center">
                    <div className="name">
                      {unlockedData.name} #{unlockedData.id}
                    </div>
                    <div className="relative">
                      <div className="w-12 mr-1 h-12">
                        <img
                          src={require(`../../assets/img/sprite/elements/${unlockedData.element}.png`)}
                          alt="Element Icon"
                        />
                      </div>
                    </div>
                    <div className="relative">
                      <div className="w-12 h-12">
                        <img
                          src={require(`../../assets/img/sprite/ranks/${unlockedData.type}.png`)}
                          alt="Rank Icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="char-img">
                    <img
                      src={require(`../../assets/img/sprite/${unlockedData.image}`)}
                      alt="Unlocked Sprite"
                    />
                  </div>
                  {/* Char detail */}
                  <div className="flex justify-center">
                    <div className="relative flex flex-col items-start">
                      <span className="text-sm font-semibold mb-1">ATK:</span>
                      <img
                        className="pr-1"
                        src={require("../../assets/img/sprite/covers/atk_detail.png")}
                        alt="ATK Icon"
                      />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <span className="font-medium text-lg text-white mt-6 ml-7">
                          0
                        </span>
                      </div>
                    </div>
                    <div className="relative flex flex-col items-start">
                      <span className="text-sm font-semibold mb-1">DEF:</span>
                      <img
                        className="pr-1"
                        src={require("../../assets/img/sprite/covers/def_detail.png")}
                        alt="DEF Icon"
                      />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <span className="font-medium text-lg text-white mt-6 ml-7">
                          0
                        </span>
                      </div>
                    </div>

                    <div className="relative flex flex-col items-start">
                      <span className="text-sm font-semibold mb-1">Lucky:</span>
                      <img
                        className="pr-1"
                        src={require("../../assets/img/sprite/covers/lucky_p_detail.png")}
                        alt="Lucky Point Icon"
                      />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <span className="font-medium text-lg text-white mt-6 ml-7">
                          0
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </>
    </div>
  );
}
