import { useMutation, useQueryClient } from "@tanstack/react-query";
import { openBox } from "../web3/app";

const useOpenBox = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["openBox"],
    mutationFn: async ({ userId, initDataRaw }: any) => {
      const item = await openBox(userId, initDataRaw);
      return item.data;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["getUser"] });
    },
  });
};

export default useOpenBox;
