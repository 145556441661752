import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { TonConnectButton } from "@tonconnect/ui-react";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import toast from "react-hot-toast";
import tonCoin from "../../assets/img/ton.png";
import {
  getUser,
  addPoint,
  getTonTxnByUser,
  swapTonToToken,
  swapTokenToTon,
  openBox,
  tokenWill,
  tonWill,
} from "../../web3/app";
import { getTonBalance, truncateString } from "../../web3/ultils";
import logo from "../../assets/img/logo.png";
import {
  initUtils,
  retrieveLaunchParams,
  useInitData,
} from "@telegram-apps/sdk-react";
import { useQueryClient } from "@tanstack/react-query";

export default function Wallet() {
  const navigate = useNavigate();
  const [tonConnect] = useTonConnectUI();
  const walletAddress = useTonAddress();
  const [userData, setUserData] = useState<any>();
  const [tonBalance, setTonBalance] = useState(0);
  const [isRefModalOpen, setRefModalOpen] = useState(false);
  const [tonValue, setTonValue] = useState<string>("");
  const [tokenValue, setTokenValue] = useState<string>("");
  const [lastModified, setLastModified] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const utils = initUtils();
  const { initDataRaw } = retrieveLaunchParams();
  const initData = useInitData();
  const teleUser = initData.user;

  // let utils;
  // let initDataRaw;
  // let teleUser = {
  //   id: `740772070`,
  //   username: `judasle7`,
  //   firstName: `Judas`,
  //   lastName: `L`,
  // };

  const handleCancelRefModal = () => {
    setRefModalOpen(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleDisconnect = async () => {
    await tonConnect.disconnect();
  };

  useEffect(() => {
    fetchTonBalance();
    const interval = setInterval(() => {
      fetchTonBalance();
      fetchUser();
    }, 60000);
    return () => clearInterval(interval);
  }, [walletAddress]);

  const fetchUser = async () => {
    const user = await getUser(teleUser.id);
    if (user) {
      const txns = await getTonTxnByUser(user.id);
      setUserData({ ...user, txns });

      if (walletAddress && user.is_wallet_connected === 0) {
        const updated = await addPoint(
          {
            tg_id: teleUser.id,
            amount: 0,
            wallet: walletAddress,
            point_type: "wallet",
          },
          initDataRaw
        );
      }
    } else {
      navigate("/", { replace: true });
    }
  };

  const fetchTonBalance = async () => {
    if (walletAddress) {
      const balance = await getTonBalance(walletAddress);
      setTonBalance(balance);
    } else {
      setTonBalance(0);
    }
  };

  // Handler cho input ton
  const handleTonClick = () => {
    setTonValue("");
    setTokenValue("");
  };

  const handleTonChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTon = e.target.value;
    setTonValue(newTon);
    setLastModified("ton");

    const tonNumber = Number(newTon);

    if (tonNumber > userData.ton) {
      toast.error("You don't have enough Ton.");
      setTokenValue("");
      return;
    }

    if (!isNaN(tonNumber) && newTon.trim() !== "") {
      try {
        const calculatedToken = await tokenWill(
          userData.id,
          tonNumber,
          initDataRaw
        );

        setTokenValue(calculatedToken.data.tokenAmountAfter);
      } catch (error) {
        console.error("Error calculating token:", error);
        setTokenValue("Error occurred while calculating token.");
      }
    } else {
      setTokenValue("");
    }
  };

  const handleTokenClick = () => {
    setTokenValue("");
    setTonValue("");
  };

  const handleTokenChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newToken = e.target.value;
    setTokenValue(newToken);
    setLastModified("token");

    const tokenNumber = Number(newToken);

    if (tokenNumber > userData.point) {
      toast.error("You don't have enough Token.");
      setTonValue("");
      return;
    }

    if (!isNaN(tokenNumber) && newToken.trim() !== "") {
      try {
        const calculatedToken = await tonWill(
          userData.id,
          tokenNumber,
          initDataRaw
        );

        setTonValue(calculatedToken.data.tonAmountAfter);
      } catch (error) {
        console.error("Error calculating token:", error);
        setTonValue("Error occurred while calculating token.");
      }
    } else {
      setTonValue("");
    }
  };

  const handleSwap = async () => {
    if (lastModified === "ton") {
      await handleSwapTonToken();
    } else if (lastModified === "token") {
      await handleSwapTokenTon();
    } else {
      toast.error("Please enter an amount to swap.");
    }
    fetchUser();
    queryClient.invalidateQueries({ queryKey: ["getUser"] });
  };

  const handleSwapTonToken = async () => {
    const amount = Number(tonValue);
    if (amount < 1) {
      toast.error("Invalid amount. Please enter a valid amount from 1 ton.");
      return;
    }

    if (amount > userData.ton) {
      toast.error("Invalid amount. Please enter a valid amount from 1 ton.");
      return;
    }

    const data = await swapTonToToken(userData.id, amount, initDataRaw);
    if (data.status === "success") {
      toast.success("Swap Balance successful!");
      setTokenValue("");
      setTonValue("");
    } else {
      toast.error(data.message);
    }
  };

  const handleSwapTokenTon = async () => {
    const amount = Number(tokenValue);
    if (amount < 100) {
      toast.error(
        "Invalid amount. Please enter a valid amount from 100 token."
      );
      return;
    }

    const data = await swapTokenToTon(userData.id, amount, initDataRaw);

    if (data.status === "success") {
      toast.success("Swap Balance successful!");
      setTonBalance(await getTonBalance(walletAddress));
      setTokenValue("");
      setTonValue("");
    } else {
      toast.error(data.message);
    }
  };

  return (
    <>
      <div className="relative wallet">
        <div className="frame-3">
          <div className="frame-2-inner">
            <div className="head">{userData?.tele_username}</div>
            <div className="badge-tab flex items-center justify-center gap-1">
              <NavLink to="/wallet" className="badge-tab-item current">
                WALLET
              </NavLink>
              <NavLink to="/wallet/history" className="badge-tab-item">
                TXNS
              </NavLink>
            </div>
            <div className="max-h-[400px] overflow-y-scroll pb-[100px]">
              <div className="flex justify-between">
                <div className="bg-transparent h-11 line-height-11 pl-10 flex justify-center w-[175px] rounded-none bg-[url('/src/assets/img/amount_bg.png')] bg-contain bg-no-repeat bg-center">
                  <span className="text-white mt-3 mr-6">
                    {Math.floor(userData?.point)}
                  </span>
                </div>
                <div className="bg-transparent h-11 line-height-11 pl-10 flex justify-center w-[175px] rounded-none bg-[url('/src/assets/img/amount_tt_bg.png')] bg-contain bg-no-repeat bg-center">
                  <span className="text-white mt-3 mr-6">{userData?.ton}</span>
                </div>
              </div>

              <span className="text-md">Input Ton/Token to swap</span>
              <div className="flex mt-1 flex-col items-center">
                {/* Input Ton */}
                <input
                  value={tonValue}
                  onClick={handleTonClick}
                  onChange={handleTonChange}
                  type="number"
                  step="0.01"
                  className="w-full p-[10px] rounded-xl bg-left bg-center bg-[url('/src/assets/img/amount_t_bg.png')] bg-[#bca282] bg-cover bg-no-repeat border-none text-center"
                  placeholder="Ton"
                />

                <input
                  value={tokenValue}
                  onClick={handleTokenClick}
                  onChange={handleTokenChange}
                  type="number"
                  step="1"
                  className="w-full p-[10px] rounded-xl bg-left bg-center bg-[url('/src/assets/img/amount_l_bg.png')] bg-[#bca282] bg-cover bg-no-repeat border-none text-center"
                  placeholder="Token"
                />
              </div>

              <div className="text-center flex justify-end gap-2">
                <button
                  className="btn is-small secondary mb-2"
                  onClick={handleSwap}
                >
                  <span>SWAP</span>
                </button>
              </div>
              <div>
                {walletAddress ? (
                  <>
                    <div className="leaderboards p-1 text-white flex items-end justify-between">
                      <div>
                        <h2 className="text-[12px] mb-1">Connected Wallet</h2>
                        <p className="text-[16px] ">
                          {truncateString(walletAddress.toString(), 4, 6)}
                        </p>
                        <div className="flex items-center gap-2 text-[16px] mt-3">
                          <div>{tonBalance.toLocaleString("en-US")}</div>
                          <img className="w-[20px]" src={tonCoin} alt="TON" />
                        </div>
                      </div>

                      <button
                        className="px-2 text-xs py-1 rounded-md text-white bg-[#562F00] flex justify-center items-center gap-2"
                        onClick={handleDisconnect}
                      >
                        <span>Disconnect</span>
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="leaderboards p-1 text-white flex items-end justify-center">
                    <TonConnectButton className="ton-connect-button" />
                  </div>
                )}

                <div
                  className={`text-center mt-1 flex justify-center gap-2 ${
                    walletAddress ? `` : `opacity-50 pointer-events-none`
                  }`}
                >
                  <NavLink
                    to="/wallet/deposit"
                    className="btn is-small secondary mb-6"
                  >
                    <span>DEPOSIT</span>
                  </NavLink>
                  <NavLink
                    to="/wallet/withdraw"
                    className="btn is-small primary"
                  >
                    <span>WITHDRAW</span>
                  </NavLink>
                </div>

                <div className="mt-4 text-center">
                  <button
                    onClick={() => setRefModalOpen(true)}
                    className="btn is-large uppercase"
                  >
                    Invite Friends
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        forceRender
        open={isRefModalOpen}
        onCancel={handleCancelRefModal}
        footer={false}
        className="modal-create-token"
      >
        <div className="text-center">
          <div className="text-[#000]">
            Invite friends
            <br />
            and get more token
          </div>
          <img className="w-[60px] m-auto mt-3" src={logo} alt="Logo" />
          <div className="mt-5">
            <div className="text-center mb-3 text-[#000]">
              Tap on the button
              <br />
              to invite your friends
            </div>

            <button
              className="btn"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_STARTAPP}?startapp=${teleUser?.id}`
                );
                toast.success("Copied!");
              }}
            >
              <span>Copy invite link</span>
            </button>
            <br />
            <button
              className="btn text-white mt-2"
              onClick={() => {
                if (utils && utils.shareURL) {
                  utils.shareURL(
                    `${process.env.REACT_APP_STARTAPP}?startapp=${teleUser?.id}`,
                    ``
                  );
                } else {
                  toast.error("Sharing not supported.");
                }
              }}
            >
              <span>Share invite link</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
